import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./my-account.css";
import logo_white from "../images/logo-white.png";
import nft_img_1 from "../images/956.png";
import my_account_img from "../images/Rectangle 31.png";
import flame_img from "../images/Flame.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import { PreLoader } from "../components/pre-loader";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div style={{ textAlign: "center" }}>
      <div id="countdown" className="countdownHolder text-white">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Myaccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [raffle, setRaffle] = useState({});
  const [my_account, setMy_account] = useState();
  const [twitter_id, setTwitter_id] = useState("");
  const [createdRaffle, setcreatedRaffle] = useState([]);
  const [purchasedRaffle, setpurchasedRaffle] = useState([]);

  const [purchasedTicketsCount, setpurchasedTicketsCount] = useState<
    any | null
  >(null);

  const [createdTicketsCount, setcreatedTicketsCount] = useState<any | null>(
    null
  );
  const [participants, setParticipants] = useState();
  const [showPurchasedTable, setShowPurchasedTable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
          setShowPurchasedTable(isMobile);
          console.log(ismobile);
        }
      },
      false
    );
  }, [isMobile]);

  const get_my_details = async () => {
    if (!publicKey) return;
    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getMyProfile/" + publicKey.toString(),
        { mode: "cors" }
      );
      const data = await response.json();
      setMy_account(data);
      setTwitter_id(data["TwitterId"]);
      let a = [];
      let b = [];
      let t_c = 0;
      let a_c = 0;
      if (
        data["purchasedTickets"] !== null &&
        data["purchasedTickets"].length > 0
      ) {
        for (let raffle of data["purchasedTickets"]) {
          if (
            raffle["transactionHash"] !== null &&
            raffle["transactionHash"] !== ""
          ) {
            let t = await RaffleData(connection, raffle["raffle_id"]);
            if (t !== undefined) {
              t["id"] = raffle["id"];
              t["raffle_id"] = raffle["raffle_id"];
              t["raffleName"] = raffle["raffleName"];

              t_c =
                t["entries"].filter((x) => x === publicKey.toString()).length +
                t_c;

              const time = new Date(t.end_timestamp * 1000);

              t["timer"] = time.getTime();

              let now = moment.utc();
              var day = moment.unix(t.end_timestamp);
              var daysDiff = day.diff(now, "days");
              var hoursDiff = day.diff(now, "hours");
              var minutesDiff = day.diff(now, "minutes");
              var secondsDiff = day.diff(now, "seconds");

              if (daysDiff > 0) {
                t["raffle_ended"] = false;
              } else {
                if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
                  t["raffle_ended"] = false;
                } else {
                  t["raffle_ended"] = true;
                }
              }

              // if (raffle["rafflePriceType"] === "nft") {
              //   let tokenmetaPubkey = await Metadata.getPDA(
              //     new PublicKey(t["prize_key"])
              //   );

              //   const tokenmeta = await Metadata.load(
              //     connection,
              //     tokenmetaPubkey
              //   );

              //   t["nft_image"] = "";
              //   t["raffleName"] = tokenmeta["data"]["data"]["name"];
              // }

              a.push(t);
            }
          }
        }
      }

      if (data["createdRaffle"] !== null && data["createdRaffle"].length > 0) {
        for (let raffle of data["createdRaffle"]) {
          if (
            raffle["transactionHash"] !== null &&
            raffle["transactionHash"] !== ""
          ) {
            let t = await RaffleData(connection, raffle["raffle_id"]);

            if (t !== undefined) {
              t["id"] = raffle["id"];
              t["raffle_id"] = raffle["raffle_id"];
              t["raffleName"] = raffle["raffleName"];

              a_c =
                t["entries"].filter((x) => x === publicKey.toString()).length +
                a_c;

              const time = new Date(t.end_timestamp * 1000);

              t["timer"] = time.getTime();

              let now = moment.utc();
              let day = moment.unix(t.end_timestamp);
              let daysDiff = day.diff(now, "days");
              let hoursDiff = day.diff(now, "hours");
              let minutesDiff = day.diff(now, "minutes");
              let secondsDiff = day.diff(now, "seconds");

              if (daysDiff > 0) {
                t["raffle_ended"] = false;
              } else {
                if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
                  t["raffle_ended"] = false;
                } else {
                  t["raffle_ended"] = true;
                }
              }

              // if (raffle["rafflePriceType"] === "nft") {
              //   let tokenmetaPubkey = await Metadata.getPDA(
              //     new PublicKey(t["prize_key"])
              //   );
              //   // console.log(tokenmetaPubkey.toString());
              //   const tokenmeta = await Metadata.load(
              //     connection,
              //     tokenmetaPubkey
              //   );

              //   t["nft_image"] = "";
              //   t["raffleName"] = tokenmeta["data"]["data"]["name"];
              // }

              b.push(t);
            }
          }
        }
      }

      setpurchasedTicketsCount(t_c);
      setcreatedTicketsCount(a_c);
      setpurchasedRaffle(a);
      setcreatedRaffle(b);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const authTwitter = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "auth/twitter/request",
      { mode: "cors" }
    );
    const data = await response.json();
    if ("url" in data) {
      localStorage.setItem("t_state", data["state"]);
      localStorage.setItem("t_code_challenge", data["code_challenge"]);
      window.location.href = data["url"];
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "my-account" && url[4] === "twitter") {
        console.log(url);
      }
    }
    // get_dao_by_id();
  }, [location]);

  useEffect(() => {
    get_my_details();

    // get_dao_by_id();
  }, [publicKey]);

  const tab_toggler = async () => {
    setShowPurchasedTable(!showPurchasedTable);
  };

  let call_raffle_details = (param) => (e) => {
    navigate("/raffle/" + param.id);
  };

  return (
    <>
      {publicKey ? (
        <>
          <div className="desk-top-view">
            {isLoading && <PreLoader />}
            {/* {!isLoading && ( */}
            <>
              <div className="my-account container-custom container-xxl d-lg-flex flex-column w-100">
                <div className="card mb-5 mt-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div
                      className={`${
                        isMobile
                          ? "flex-wrap flex-sm-nowrap mb-3"
                          : "d-flex flex-wrap flex-sm-nowrap mb-3"
                      }`}
                    >
                      <div className="flex-grow-1">
                        <div
                          className={`${
                            isMobile
                              ? "justify-content-between align-items-start flex-wrap mb-2"
                              : "d-flex justify-content-between align-items-start flex-wrap mb-2"
                          }`}
                        >
                          <div
                            className={`${
                              isMobile ? "flex-column" : "d-flex flex-column"
                            }`}
                          >
                            <div
                              className={`${
                                isMobile
                                  ? "align-items-center mb-1"
                                  : "d-flex align-items-center mb-1"
                              }`}
                            >
                              <a
                                href="#"
                                className="text-white fs-1 fw-bolder me-3"
                              >
                                My Account
                              </a>
                            </div>
                            <div
                              className={`${
                                isMobile
                                  ? " align-items-center mb-2"
                                  : "d-flex align-items-center mb-2"
                              }`}
                            >
                              <span className="text-yellow text-hover-primary fs-5 fw-bold me-1">
                                {publicKey ? publicKey.toString() : ""}
                              </span>
                            </div>
                          </div>

                          <div className="d-flex my-4">
                            {twitter_id === "" && (
                              <button
                                onClick={authTwitter}
                                className="btn twiiter-connect-btn me-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  className="inline-block w-6 ml-2 text-white-40 fill-current"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>
                                Link Twiiter
                              </button>
                            )}
                            {twitter_id !== "" && (
                              <div>
                                <div className="twitter-user-name-label">
                                  Twitter Username
                                </div>
                                <div className="twitter-user-name">
                                  <a
                                    href={"https://twitter.com/" + twitter_id}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {twitter_id}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="inline-block w-6 ml-2 text-blue-400 fill-current"
                                    >
                                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <div>
                                {/* <img className="my-account-img" src={my_account_img} /> */}
                              </div>

                              <div className="my-account-small-box min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="label">
                                  No of Raffle Created
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text">
                                    {createdRaffle !== undefined
                                      ? createdRaffle.length
                                      : 0}
                                  </div>
                                </div>
                              </div>
                              <div className="my-account-small-box-2 min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="label">
                                  No of Purchased ticket
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="text">
                                    {purchasedRaffle !== undefined
                                      ? purchasedTicketsCount
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <>
                    <div className="slider-container-myaccount">
                      <label className="switch-myaccount">
                        <input
                          type="checkbox"
                          onChange={() => tab_toggler()}
                          id="toggle_vat"
                          className="toggle_vat"
                        />
                        <div className="slider round">
                          <span className="slider_text">
                            <span className="off">Purchased</span>
                            <span className="on">Created</span>
                          </span>
                        </div>
                      </label>
                    </div>
                    {showPurchasedTable && (
                      <div className="row gy-5 g-xl-10">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bold text-white recent-raffle-text">
                            <img className="flame-img" src={flame_img} />{" "}
                            Purchased Raffle
                            <img className="flame-img" src={flame_img} />
                          </span>
                        </h3>
                        <div className="col-xl-12">
                          <div className="my-account-table-card card card-flush h-xl-100">
                            <div className="card-body">
                              <div
                                id="kt_table_widget_5_table_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <div className="table-responsive">
                                  <table
                                    className="table align-middle fs-6 gy-3 dataTable no-footer"
                                    id="kt_table_widget_5_table"
                                  >
                                    <thead>
                                      <tr className="text-start text-blue fw-bold fs-7 text-uppercase gs-0">
                                        <th
                                          className="pe-3 min-w-100px sorting_disabled text-center"
                                          aria-label="Product ID"
                                        >
                                          Raffle
                                        </th>
                                        <th
                                          className="pe-3 min-w-150px sorting text-center"
                                          aria-controls="kt_table_widget_5_table"
                                          aria-label="Date Added: activate to sort column ascending"
                                        >
                                          Time Remaining
                                        </th>
                                        <th
                                          className=" pe-3 min-w-100px text-center sorting"
                                          aria-controls="kt_table_widget_5_table"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Tickets Remaining
                                        </th>

                                        <th
                                          className=" pe-0 min-w-25px sorting text-center"
                                          aria-controls="kt_table_widget_5_table"
                                          aria-label="Qty: activate to sort column ascending"
                                        >
                                          Ticket Bought
                                        </th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    {purchasedRaffle !== undefined && (
                                      <tbody className="fw-bold text-gray-600">
                                        {purchasedRaffle.map(
                                          (raffle, index) => (
                                            <tr className="odd">
                                              <td className="text-white text-center">
                                                {raffle["raffleName"]}
                                              </td>

                                              <td
                                                className="text-white text-center"
                                                data-order="2022-04-20T00:00:00+05:30"
                                              >
                                                {raffle["raffle_ended"] ? (
                                                  "Ended"
                                                ) : (
                                                  <MyTimer
                                                    expiryTimestamp={
                                                      raffle["timer"]
                                                    }
                                                  />
                                                )}
                                              </td>

                                              <td className="text-white text-center">
                                                {raffle["total_entries"]}/
                                                {raffle["max_entries"]}{" "}
                                                {/* <div className="text-blue fs-7">0.1 DUST</div> */}
                                              </td>

                                              <td className="text-white text-center">
                                                {
                                                  raffle["entries"].filter(
                                                    (x) =>
                                                      x === publicKey.toString()
                                                  ).length
                                                }
                                              </td>
                                              <td className="text-end text-center">
                                                <button
                                                  onClick={call_raffle_details(
                                                    raffle
                                                  )}
                                                  className="btn btn-view-myaccount"
                                                >
                                                  View
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!showPurchasedTable && (
                      <div className="row gy-5 g-xl-10">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bold text-white recent-raffle-text">
                            <img className="flame-img" src={flame_img} />{" "}
                            Created Raffle
                            <img className="flame-img" src={flame_img} />
                          </span>
                        </h3>
                        <div className="col-xl-12">
                          <div className="my-account-table-card card card-flush h-xl-100">
                            <div className="card-body">
                              <div
                                id="kt_table_widget_5_table_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <div className="table-responsive">
                                  <table
                                    className="table align-middle fs-6 gy-3 dataTable no-footer"
                                    id="kt_table_widget_5_table"
                                  >
                                    <thead>
                                      <tr className="text-start text-blue fw-bold fs-7 text-uppercase gs-0">
                                        <th
                                          className="pe-3 min-w-100px sorting_disabled text-center"
                                          aria-label="Product ID"
                                        >
                                          Raffle
                                        </th>
                                        <th
                                          className="pe-3 min-w-150px sorting text-center"
                                          aria-controls="kt_table_widget_5_table"
                                          aria-label="Date Added: activate to sort column ascending"
                                        >
                                          Time Remaining
                                        </th>
                                        <th
                                          className=" pe-3 min-w-100px text-center sorting"
                                          aria-controls="kt_table_widget_5_table"
                                          aria-label="Price: activate to sort column ascending"
                                        >
                                          Tickets Remaining
                                        </th>

                                        <th></th>
                                      </tr>
                                    </thead>
                                    {createdRaffle !== undefined && (
                                      <tbody className="fw-bold text-gray-600">
                                        {createdRaffle.map((raffle, index) => (
                                          <tr className="odd">
                                            <td className="text-white text-center">
                                              {raffle["raffleName"]}
                                            </td>

                                            <td
                                              className="text-white text-center"
                                              data-order="2022-04-20T00:00:00+05:30"
                                            >
                                              {raffle["raffle_ended"] ? (
                                                "Ended"
                                              ) : (
                                                <MyTimer
                                                  expiryTimestamp={
                                                    raffle["timer"]
                                                  }
                                                />
                                              )}
                                            </td>

                                            <td className="text-white text-center">
                                              {raffle["total_entries"]}/
                                              {raffle["max_entries"]}{" "}
                                              {/* <div className="text-blue fs-7">0.1 DUST</div> */}
                                            </td>

                                            <td className="text-end text-center">
                                              <button
                                                onClick={call_raffle_details(
                                                  raffle
                                                )}
                                                className="btn btn-view-myaccount"
                                              >
                                                View
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isMobile && (
                  <>
                    <div className="row gy-5 g-xl-10">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bold text-white recent-raffle-text">
                          <img className="flame-img" src={flame_img} />{" "}
                          Purchased Raffle
                          <img className="flame-img" src={flame_img} />
                        </span>
                      </h3>
                      <div className="col-xl-12">
                        <div className="my-account-table-card card card-flush h-xl-100">
                          <div className="card-body">
                            <div
                              id="kt_table_widget_5_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_5_table"
                                >
                                  <thead>
                                    <tr className="text-start text-blue fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="pe-3 min-w-100px sorting_disabled text-center"
                                        aria-label="Product ID"
                                      >
                                        Raffle
                                      </th>
                                      <th
                                        className="pe-3 min-w-150px sorting text-center"
                                        aria-controls="kt_table_widget_5_table"
                                        aria-label="Date Added: activate to sort column ascending"
                                      >
                                        Time Remaining
                                      </th>
                                      <th
                                        className=" pe-3 min-w-100px text-center sorting"
                                        aria-controls="kt_table_widget_5_table"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Tickets Remaining
                                      </th>

                                      <th
                                        className=" pe-0 min-w-25px sorting text-center"
                                        aria-controls="kt_table_widget_5_table"
                                        aria-label="Qty: activate to sort column ascending"
                                      >
                                        Ticket Bought
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  {purchasedRaffle !== undefined && (
                                    <tbody className="fw-bold text-gray-600">
                                      {purchasedRaffle.map((raffle, index) => (
                                        <tr className="odd">
                                          <td className="text-white text-center">
                                            {raffle["raffleName"]}
                                          </td>

                                          <td
                                            className="text-white text-center"
                                            data-order="2022-04-20T00:00:00+05:30"
                                          >
                                            {raffle["raffle_ended"] ? (
                                              "Ended"
                                            ) : (
                                              <MyTimer
                                                expiryTimestamp={
                                                  raffle["timer"]
                                                }
                                              />
                                            )}
                                          </td>

                                          <td className="text-white text-center">
                                            {raffle["total_entries"]}/
                                            {raffle["max_entries"]}{" "}
                                            {/* <div className="text-blue fs-7">0.1 DUST</div> */}
                                          </td>

                                          <td className="text-white text-center">
                                            {
                                              raffle["entries"].filter(
                                                (x) =>
                                                  x === publicKey.toString()
                                              ).length
                                            }
                                          </td>
                                          <td className="text-end text-center">
                                            <button
                                              onClick={call_raffle_details(
                                                raffle
                                              )}
                                              className="btn btn-view-myaccount"
                                            >
                                              View
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* created table */}
                    <div className="row gy-5 g-xl-10 mt-3">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bold text-white recent-raffle-text">
                          <img className="flame-img" src={flame_img} /> Created
                          Raffle
                          <img className="flame-img" src={flame_img} />
                        </span>
                      </h3>
                      <div className="col-xl-12">
                        <div className="my-account-table-card card card-flush h-xl-100">
                          <div className="card-body">
                            <div
                              id="kt_table_widget_5_table_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="table-responsive">
                                <table
                                  className="table align-middle fs-6 gy-3 dataTable no-footer"
                                  id="kt_table_widget_5_table"
                                >
                                  <thead>
                                    <tr className="text-start text-blue fw-bold fs-7 text-uppercase gs-0">
                                      <th
                                        className="pe-3 min-w-100px sorting_disabled text-center"
                                        aria-label="Product ID"
                                      >
                                        Raffle
                                      </th>
                                      <th
                                        className="pe-3 min-w-150px sorting text-center"
                                        aria-controls="kt_table_widget_5_table"
                                        aria-label="Date Added: activate to sort column ascending"
                                      >
                                        Time Remaining
                                      </th>
                                      <th
                                        className=" pe-3 min-w-100px text-center sorting"
                                        aria-controls="kt_table_widget_5_table"
                                        aria-label="Price: activate to sort column ascending"
                                      >
                                        Tickets Remaining
                                      </th>

                                      <th></th>
                                    </tr>
                                  </thead>
                                  {createdRaffle !== undefined && (
                                    <tbody className="fw-bold text-gray-600">
                                      {createdRaffle.map((raffle, index) => (
                                        <tr className="odd">
                                          <td className="text-white text-center">
                                            {raffle["raffleName"]}
                                          </td>

                                          <td
                                            className="text-white text-center"
                                            data-order="2022-04-20T00:00:00+05:30"
                                          >
                                            {raffle["raffle_ended"] ? (
                                              "Ended"
                                            ) : (
                                              <MyTimer
                                                expiryTimestamp={
                                                  raffle["timer"]
                                                }
                                              />
                                            )}
                                          </td>

                                          <td className="text-white text-center">
                                            {raffle["total_entries"]}/
                                            {raffle["max_entries"]}{" "}
                                            {/* <div className="text-blue fs-7">0.1 DUST</div> */}
                                          </td>

                                          <td className="text-end text-center">
                                            <button
                                              onClick={call_raffle_details(
                                                raffle
                                              )}
                                              className="btn btn-view-myaccount"
                                            >
                                              View
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
            {/* )} */}
          </div>
          <div className="mobile-view text-white fs-2">
            <img src={logo_white} />
            <div>Please use this app in desktop for better experience</div>
          </div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
