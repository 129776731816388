import React, { useEffect, useState } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate } from "react-router-dom";

import { PublicKey } from "@solana/web3.js";
import "./my-account.css";
import Swal from "sweetalert2";

import { PreLoader } from "../components/pre-loader";



export const TwitterAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();

  const navigate = useNavigate();








  const authTwitter =async () => {

    const response = await fetch(
      // process.env.REACT_APP_API_URL + "getMyProfile/" + publicKey.toString(),
      process.env.REACT_APP_API_URL + "auth/twitter/request",
      { mode: "cors" }
    );
    const data = await response.json();
    if('url' in data){
      window.location.href = data['url'];
    }

  }


  const call_twitter_api_with_code = async (code) => {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "/user/updateTwitterID",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            code: code,
            wallet_address: publicKey.toString(),
            state: localStorage.getItem('t_state'),
            code_challenge: localStorage.getItem('t_code_challenge')
          }),
        }
      );
      if (response.status === 201) {
        Swal.fire({
          text: "Your twitter account successfully linked",
          confirmButtonText: "Close",
        });
        navigate("/my-account/");
      }else{
        Swal.fire({
          text: "Something went wrong. please try again after sometime",
          confirmButtonText: "Close",
        });
        navigate("/my-account/");
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        text: "Something went wrong. please try again after sometime",
        confirmButtonText: "Close",
      });
      navigate("/my-account/");
    }
  };


  useEffect(() => {
   if(publicKey){
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "twitter") {
      let code = url[4].split("&")
        if(code.length === 2){
          code = code[1].split('=')
          if(code.length>0){
            console.log(code[1])
            console.log(document.cookie)
            call_twitter_api_with_code(code[1])
          }
        }
      }
    }
   }

    // get_dao_by_id();
  }, [location, publicKey]);







  return (
    <>
    <PreLoader />

    </>
  );
};
