import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { LAMPORTS_PER_SOL, PublicKey, Keypair } from "@solana/web3.js";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import { PreLoader } from "../components/pre-loader";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import nft_img from "../images/art.png";
import token_img from "../images/token.png";
import wallet_img from "../images/wallet.png";
import twitter_img from "../images/twitter.png";
import reach_us_img from "../images/contact-us.png";
import isURL from "validator/lib/isURL";
import discord_img from "../images/discord.png";
import { useStakedNfts } from "../hooks/useStakedNfts";
import { useUnstakedNfts } from "../hooks/useUnstakedNfts";

import {
  createRaffleInitTransaction,
  RaffleData,
  getAllTokens,
  getAllNFT,
} from "../util/raffle";

import "./create-raffle.css";

const hourMilliSeconds = 1000 * 3600;

const addDays = (d, num) => {
  let time = d.getTime();
  time += hourMilliSeconds * 24 * num;
  return new Date(time);
};

const getNearestHour = () => {
  const now = new Date().getTime();
  return new Date(now - (now % hourMilliSeconds) + hourMilliSeconds);
};

export const CreateIndividualRaffle = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet();
  const { publicKey, sendTransaction } = useWallet();
  const navigate = useNavigate();
  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  const stakedNfts = useStakedNfts(refreshHandle);
  const unstakedNfts = useUnstakedNfts(refreshHandle);
  const [h, setH] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNFT, setIsLoadingNFT] = useState(false);
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [raffleType, setraffleType] = useState("individual");
  const [raffleFor, setraffleFor] = useState("");
  const [raffleWord, setraffleWord] = useState("");
  const [raffleName, setraffleName] = useState("");
  const [DaoName, setDaoName] = useState("");
  const [DaoUrl, setDaoUrl] = useState("");
  const [priceType, setpriceType] = useState({});
  const [priceType1, setpriceType1] = useState({});
  const [priceTypeOption, setpriceTypeOption] = useState(0);
  const [priceTypeOption1, setpriceTypeOption1] = useState(0);
  const [ticketSupply, setticketSupply] = useState("");
  const [ticketThreshold, setticketThreshold] = useState(0);
  const [ticketType, setticketType] = useState("1");
  const [ticketPrice, setticketPrice] = useState("");
  const [ticketPrice1, setticketPrice1] = useState("");
  const [ticketPrice2, setticketPrice2] = useState("");
  const [ticketTokenAddress1, setticketTokenAddress1] = useState("");
  const [ticketTokenAddress2, setticketTokenAddress2] = useState("");
  const [maxTicketPerWallet, setmaxTicketPerWallet] = useState("");
  const [winnerCount, setwinnerCount] = useState<any | null>(null);
  const [priceAmountPerWinner, setpriceAmountPerWinner] = useState("");
  const [endTime, setendTime] = useState(addDays(getNearestHour(), 0));
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [disabledHours, setDisabledHours] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [whitelistedToken, setwhitelistedToken] = useState([]);
  const [selectedToken, setselectedToken] = useState("");
  const [selectedNFTCmid, setselectedNFTCmid] = useState("");
  const [selectedNFTImage, setselectedNFTImage] = useState("");
  const [currentUserType, setcurrentUserType] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const [discordLink, setdiscordLink] = useState("");
  const [twitterLink, settwitterLink] = useState("");

  const [NFTModal, setNFTModal] = useState(false);

  const [TokenModal, setTokenModal] = useState(false);
  const [selectedFilName, setselectedFilName] = useState("");
  const [uploadedFile, setuploadedFile] = useState();
  const [file, setFile] = useState<any | null>(null);
  const fileInput: any = useRef();
  const [fileNameFrReq, setFileNameFrReq] = useState<any | null>(null);
  const [individualTwitterName, setIndividualTwitterName] = useState("");

  const [creatorGuideModal, setcreatorGuideModal] = useState(false);
  const [rent, setRent] = useState(0);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    if (stakedNfts.length > 0 || unstakedNfts.length > 0) {
      setH(1);
    }
    // stakedNfts.map((nft, index) => {

    //   console.log(nft)
    // });

    // unstakedNfts.map((nft, index) => {
    //   console.log(nft)
    //   });
  }, [wallet, stakedNfts, unstakedNfts]);

  const ticket_list = JSON.parse(process.env.REACT_APP_TICKET_TOKEN);

  const ticket_list_1 = JSON.parse(process.env.REACT_APP_TICKET_TOKEN_1);

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const sendAndConfirmAccountTransaction = useCallback(
    async (transaction, dataAddress: Keypair) => {
      try {
        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;
        transaction.sign(dataAddress);

        let signature = await sendTransaction(transaction, connection);
        await connection.confirmTransaction(signature, "confirmed");

        // if (onChange) onChange();
        return signature;
      } catch (error: any) {
        setIsLoading(false);
        console.log(error.message);
        if (error.message !== "User rejected the request.") {
          if (error.message.indexOf("custom program error: 0x103") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "Raffle End Date should be minimum of 24 hour",
              confirmButtonText: "Close",
            });
          } else if (error.message.indexOf("custom program error: 0x1") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "You don't have enough SOL balance to create Raffle, Please try after adding the balance on your wallet",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: error.message,
              confirmButtonText: "Close",
            });
          }
        }
      }
    },
    [connection, publicKey, sendTransaction]
  );

  let moveStep = async (e) => {
    if (e === "step1") {
      setStep1(true);
      // setselectedFilName("");

      setStep2(false);
    } else if (e === "step2") {
      let error = false;
      if (raffleFor === "") {
        Swal.fire({
          text: "Please select raffle price type",
          confirmButtonText: "Close",
        });
        error = true;
      }
      if (raffleFor === "wallet" || raffleFor === "token") {
        if (raffleFor === "token" && selectedToken === "") {
          Swal.fire({
            text: "Please select the token",
            confirmButtonText: "Close",
          });
          error = true;
        }

        if (raffleName === "") {
          Swal.fire({
            text: "Raffle name can't be empty",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (winnerCount === "" || winnerCount === null) {
          Swal.fire({
            text: "Winner Count Can't be empty",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (winnerCount <= 0) {
          Swal.fire({
            text: "Winner Count Should be greater then zero",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (!file) {
          Swal.fire({
            text: "Please upload raffle image",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }
      }

      if (raffleFor === "wallet" || selectedToken !== "") {
        if (raffleFor !== "nft") {
          if (uploadedFile) {
            let newFileName;
            newFileName = Math.random() + "_" + uploadedFile["name"];

            setFileNameFrReq(newFileName);
          }

          // let file = fileInput!.current.files[0];
          // if (file !== undefined) {
          //   setFile(file);

          //   let newFileName = fileInput!.current.files[0].name.replace(
          //     /\..+$/,
          //     ""
          //   );
          //   newFileName = Math.random() + "_" + file.name;
          //   setFileNameFrReq(newFileName);
          // }
        }
        if (raffleFor === "nft") {
          await setwinnerCount("1");
          await setpriceAmountPerWinner("1");
        }
        if (raffleFor === "wallet") {
          await setpriceAmountPerWinner("1");
        }
        if (!error) {
          setStep1(false);
          setStep2(true);
        }
      } else {
        Swal.fire({
          text: "Please select the category",
          confirmButtonText: "Close",
        });
      }
    }
  };

  let onRaffleChange = async (e) => {
    setselectedToken("");
    setraffleType(e);
  };

  let onPriceTypeChange = async (e) => {
    setpriceType(ticket_list[e]);
    setpriceTypeOption(e);
  };

  let onPriceTypeChange1 = async (e) => {
    setpriceType1(ticket_list_1[e]);
    setpriceTypeOption1(e);
  };

  let onTicketTypeChange = async (e) => {
    // setpriceType(ticket_list[e]);
    // setpriceTypeOption(e);
    setticketType(e);
  };

  let onRaffleTypeChange = async (e) => {
    setraffleFor(e);
  };

  const validateUrl = (e) => {
    // setVal(e.target.value);
    if (isURL(e.target.value)) {
      setdiscordLink(e.target.value);
    } else {
      Swal.fire({
        text: "Invalid URL",
        confirmButtonText: "Close",
      });
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(votingEndTime.toISOString());
    let isfileRquired = false;

    // if (raffleFor === "token" || raffleFor === "wallet") {
    //   if (!isURL(discordLink)) {
    //     Swal.fire({
    //       text: "Invalid Discord URL",
    //       confirmButtonText: "Close",
    //     });
    //     return;
    //   }
    //   if (!isURL(twitterLink)) {
    //     Swal.fire({
    //       text: "Invalid Twitter URL",
    //       confirmButtonText: "Close",
    //     });
    //     return;
    //   }

    //   if (file) {
    //     isfileRquired = true;
    //   } else {
    //     isfileRquired = false;
    //   }
    // } else {
    //   isfileRquired = true;
    // }
    if (selectedToken === "") {
      Swal.fire({
        text: "Please select the NFT",
        confirmButtonText: "Close",
      });
      return;
    }

    if (ticketType === "1") {
      if (Number(ticketPrice) <= 0) {
        Swal.fire({
          text: "Ticket price is invaild",
          confirmButtonText: "Close",
        });
        return;
      }
    }

    if (ticketType === "2") {
      if (Number(ticketPrice) <= 0) {
        Swal.fire({
          text: "Ticket price is invaild",
          confirmButtonText: "Close",
        });
        return;
      }

      if (Number(ticketPrice1) <= 0) {
        Swal.fire({
          text: "Ticket price 2 is invaild",
          confirmButtonText: "Close",
        });
        return;
      }
    }

    if (ticketSupply !== "" && Number(ticketSupply) > 5000) {
      Swal.fire({
        text: "Ticket size should be less then or equal to 5000",
        confirmButtonText: "Close",
      });
      return;
    }
    if (ticketSupply !== "" && ticketPrice !== "") {
      // if (raffleFor === "token" || raffleFor === "wallet") {
      //   if (Number(winnerCount) > Number(ticketSupply)) {
      //     Swal.fire({
      //       text: "Ticket size should be greater then winner count",
      //       confirmButtonText: "Close",
      //     });
      //     return;
      //   }
      // }
      if (maxTicketPerWallet === "") {
        setmaxTicketPerWallet(ticketSupply);
      }

      if (ticketType === "2") {
        if (priceType["Token_name"] === priceType1["Token_name"]) {
          Swal.fire({
            text: "Ticket Price and Ticket Price 2 should be different token",
            confirmButtonText: "Close",
          });
          return;
        }
      }

      setIsLoading(true);
      let endTimeasms = Math.floor(moment(endTime).valueOf() / 1000);

      localStorage.setItem("raffleType", raffleType);
      localStorage.setItem("discordLink", discordLink);
      localStorage.setItem("twitterLink", twitterLink);

      localStorage.setItem("raffleName", raffleName);
      localStorage.setItem("raffleFor", raffleFor);
      localStorage.setItem("ticketSupply", ticketSupply);
      localStorage.setItem("ticketPrice", ticketPrice);
      if (maxTicketPerWallet === "") {
        localStorage.setItem("maxTicketPerWallet", ticketSupply);
      } else {
        localStorage.setItem("maxTicketPerWallet", maxTicketPerWallet);
      }

      localStorage.setItem("winnerCount", "1");
      localStorage.setItem("priceAmountPerWinner", "1");
      localStorage.setItem("prize_token", selectedToken);
      localStorage.setItem("priceType", JSON.stringify(priceType));
      localStorage.setItem("daoName", DaoName);
      localStorage.setItem("daoUrl", DaoUrl);
      if (raffleFor === "nft") {
        localStorage.setItem("cmid", selectedNFTCmid);
        localStorage.setItem("nftId", selectedToken);
        let img = await fetch(selectedNFTImage);
        let img_n = (Math.random() + 1).toString(36).substring(2);

        let imageBlog = await img.blob();

        let img_t = imageBlog["type"].split("/");
        img_n = img_n + "." + img_t[1];

        localStorage.setItem("fileNameFrReq", img_n);
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };

        const ReactS3Client = new S3(config);
        // ReactS3Client.uploadFile(imageBlog, img_n).then((data) => {
        //   if (data.status === 204) {
        //     console.log("success");
        //     uploadToDb(
        //       endTimeasms,
        //       ticketPrice,
        //       ticketPrice1,
        //       ticketSupply,
        //       selectedToken,
        //       priceType,
        //       priceType1,
        //       ticketType,
        //       h,
        //       individualTwitterName,
        //       selectedNFTCmid,
        //       imageBlog
        //     );
        //   } else {
        //     console.log("fail");
        //     Swal.fire({
        //       text: "Image not uploaded. please try again",
        //       confirmButtonText: "Close",
        //     });
        //   }
        // });
        uploadToDb(
          endTimeasms,
          ticketPrice,
          ticketPrice1,
          ticketSupply,
          selectedToken,
          priceType,
          priceType1,
          ticketType,
          h,
          individualTwitterName,
          selectedNFTCmid,
          selectedNFTImage
        );
      } else {
        localStorage.setItem("nftId", "");
        localStorage.setItem("fileNameFrReq", fileNameFrReq);
        if (file) {
          const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL,
          };

          const ReactS3Client = new S3(config);
          ReactS3Client.uploadFile(uploadedFile, fileNameFrReq).then((data) => {
            if (data.status === 204) {
              console.log("success");

              uploadToDb(
                endTimeasms,
                ticketPrice,
                ticketPrice1,
                ticketSupply,
                selectedToken,
                priceType,
                priceType1,
                ticketType,
                h,
                individualTwitterName,
                selectedNFTCmid,
                uploadedFile
              );
            } else {
              console.log("fail");
              Swal.fire({
                text: "Image not uploaded. please try again",
                confirmButtonText: "Close",
              });
            }
          });
        }
      }
    } else {
      Swal.fire({
        text: "Some fields are empty or invalid.Please review all the fields",
        confirmButtonText: "Close",
      });
      setIsLoading(false);
    }
  };

  let individual_details = async (individual_id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getIndividualDetails/" + individual_id,
        { mode: "cors" }
      );
      if (response.status === 200) {
        const data = await response.json();
        setIndividualTwitterName(data["twiiterId"]);
      } else {
        setIndividualTwitterName("");
      }
    } catch (e) {}
  };

  let uploadToDb = async (
    endTimeasms,
    ticketPrice,
    ticketPrice1,
    ticketSupply,
    selectedToken,
    priceType,
    priceType1,
    ticketType,
    h_only,
    twitter_name,
    cmid,
    file
  ) => {
    try {
      console.log(file);
      let r_name = localStorage.getItem("raffleName");
      if (raffleFor === "wallet") {
        r_name =
          localStorage.getItem("raffleName") +
          " - " +
          localStorage.getItem("winnerCount") +
          " SPOTS";
      }

      let ticket_price = 0;
      let ticket_price_1 = 0;
      let ticket_prize_type;
      let ticket_prize_type_1;
      let ticket_type;
      if (ticketType === "1") {
        ticket_price = ticketPrice;
        ticket_price_1 = 0;
        ticket_prize_type = priceType.Token_name;
        ticket_prize_type_1 = "";
        ticket_type = "single";
      } else {
        ticket_price = ticketPrice;
        ticket_price_1 = ticketPrice1;
        ticket_prize_type = priceType.Token_name;
        ticket_prize_type_1 = priceType1.Token_name;
        ticket_type = "multi";
      }

      let percent_of_ticket_supply =
        (40 / 100) * Number(localStorage.getItem("ticketSupply"));
      let threshold_tickets = 900;
      if (percent_of_ticket_supply < threshold_tickets) {
        threshold_tickets = percent_of_ticket_supply;
      }

      let response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffleWord",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleName: r_name,
            raffleType: localStorage.getItem("raffleType"),
            attachemnet: file,
            rafflePriceType: localStorage.getItem("raffleFor"),
            creatorWalletAddress: publicKey.toString(),
            individualNameorAddress: publicKey.toString(),
            nftId: localStorage.getItem("prize_token"),
            projectTwitterLink: localStorage.getItem("twitterLink"),
            projectDiscordLink: localStorage.getItem("discordLink"),
            nft_mint_address: selectedToken,
            tickt_size: ticketSupply,
            ticket_type: ticket_type,
            ticket_prize: ticketPrice,
            ticket_prize_1: ticket_price_1,
            ticket_prize_type: ticket_prize_type,
            ticket_prize_type_1: ticket_prize_type_1,
            raffleEndTime: endTimeasms.toString(),
            isH: h_only,
            creatorTwitterId: twitter_name,
            nft_cmid_address: cmid,
            threshold: threshold_tickets,
          }),
        }
      );

      const data = await response.json();

      setraffleWord(data);
      localStorage.setItem("raffleword", data);
      localStorage.setItem("endTime", endTimeasms.toString());
      setIsLoading(true);
      await RaffleInit(
        data["raffle_word"],
        data["id"],
        ticketPrice,
        ticketPrice1,
        priceType,
        priceType1,
        ticketType,
        h_only
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const RaffleInit = useCallback(
    async (
      r_word,
      r_id,
      t_price,
      t_price1,
      p_type,
      p_type1,
      t_type,
      h_only
    ) => {
      if (!publicKey) return;
      var dataAddress;
      dataAddress = Keypair.generate();

      let isDao = 0;
      let hasEscrow = 0;
      let checkWLtoken;
      let prize_token;
      let prize_amount;
      let priceType = JSON.parse(localStorage.getItem("priceType"));

      if (localStorage.getItem("raffleType") === "dao") {
        isDao = 1;
      }
      if (localStorage.getItem("raffleFor") === "token") {
        hasEscrow = 1;
        checkWLtoken = new PublicKey(localStorage.getItem("prize_token"));
        prize_token = new PublicKey(localStorage.getItem("prize_token"));
        prize_amount =
          Number(localStorage.getItem("priceAmountPerWinner")) * 1000000000;
      }
      if (localStorage.getItem("raffleFor") === "nft") {
        hasEscrow = 1;

        checkWLtoken = new PublicKey(localStorage.getItem("cmid"));
        prize_token = new PublicKey(localStorage.getItem("prize_token"));
        prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
      }
      if (localStorage.getItem("raffleFor") === "wallet") {
        checkWLtoken = new PublicKey(process.env.REACT_APP_WALLET_PRICE_KEY);
        prize_token = new PublicKey(process.env.REACT_APP_WALLET_PRICE_KEY);
        prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
      }

      let ticket_price = 0;
      let ticket_price_1 = 0;
      let ticket_price_2 = 0;
      let ticket_token_1;
      let ticket_token_2;

      if (t_type === "1") {
        if (p_type.Token_name === "SOL") {
          ticket_price = Number(t_price * Math.pow(10, 9));
          ticket_price_1 = 0;
          ticket_price_2 = 0;
          ticket_token_1 = p_type1.Address;
          ticket_token_2 = p_type1.Address;
        } else {
          ticket_price = 0;
          ticket_price_1 = Number(t_price) * Math.pow(10, p_type["Decimal"]);
          ticket_price_2 = 0;
          ticket_token_1 = p_type.Address;
          ticket_token_2 = p_type1.Address;
        }
      } else {
        if (p_type.Token_name === "SOL") {
          ticket_price = Number(t_price * Math.pow(10, 9));
          ticket_price_1 = Number(t_price1) * Math.pow(10, p_type1["Decimal"]);
          ticket_price_2 = 0;
          ticket_token_1 = p_type1.Address;
          ticket_token_2 = p_type1.Address;
        } else {
          ticket_price = 0;
          ticket_price_1 = Number(t_price) * Math.pow(10, p_type["Decimal"]);
          ticket_price_2 = Number(t_price1) * Math.pow(10, p_type1["Decimal"]);
          ticket_token_1 = p_type.Address;
          ticket_token_2 = p_type1.Address;
        }
      }
      let sig: any;

      let percent_of_ticket_supply =
        (40 / 100) * Number(localStorage.getItem("ticketSupply"));
      let threshold_tickets = 900;
      if (percent_of_ticket_supply < threshold_tickets) {
        threshold_tickets = percent_of_ticket_supply;
      }

      sig = await sendAndConfirmAccountTransaction(
        await createRaffleInitTransaction(
          connection,
          publicKey,
          r_word,
          Number(localStorage.getItem("winnerCount")),
          prize_amount,
          localStorage.getItem("endTime"),
          Number(localStorage.getItem("ticketSupply")),
          ticket_price,
          ticket_price_1,
          ticket_price_2,
          Number(localStorage.getItem("maxTicketPerWallet")),
          isDao,
          hasEscrow,
          new PublicKey(ticket_token_1),
          new PublicKey(ticket_token_2),
          prize_token,
          dataAddress.publicKey,
          checkWLtoken,
          Number(h_only),
          0,
          new PublicKey("2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj"),
          threshold_tickets
        ),
        dataAddress
      );
      if (sig) {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "updateRaffle",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              raffleWord: r_word,
              id: r_id,
              transactionHash: sig,
            }),
          }
        );
        let access_key = await access_res.json();
        setIsLoading(false);
        Swal.fire({
          text: "Your Raffle has been created.",
          confirmButtonText: "Click to view Raffle",
        }).then((sig) => {
          navigate("/raffle/" + r_id, {
            replace: true,
          });
        });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const handleCloseNFTModal = () => {
    setNFTModal(false);
    setselectedToken("");
    setselectedNFTImage("");
    setraffleFor("");
  };

  const handleCloseTokenModal = () => {
    setTokenModal(false);
    setselectedToken("");
    setraffleFor("");
  };

  const handleCloseCreatorGuideModal = () => {
    setcreatorGuideModal(false);
  };

  const get_raffle_word = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffleWord",
        { mode: "cors" }
      );
      const data = await response.text();

      setraffleWord(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitTokenModal = () => {
    if (selectedToken === "") {
      Swal.fire({
        text: "Please select the NFT to create raffle",
        confirmButtonText: "Close",
      });
    } else {
      setTokenModal(false);
      setNFTModal(false);
      changeNftBackgroundImage();
    }
  };

  let onChooseToken = async (type) => {
    setraffleFor(type);
    let res;
    if (type === "nft") {
      setIsLoadingNFT(true);
      res = await getAllNFT(connection, publicKey);

      setwhitelistedToken(res);
      setIsLoadingNFT(false);
    } else if (type === "token") {
      res = await getAllTokens(connection, publicKey);

      setwhitelistedToken(res);
    } else if (type === "wallet") {
      setselectedToken("");
    }
  };

  let changeNftBackgroundImage = async () => {
    console.log(selectedNFTImage);
  };
  const handleImageUpload = (event) => {
    if (event.target.files.length > 0) {
      let fileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );

      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        setuploadedFile(event.target.files[0]);
        setselectedFilName(event.target.files[0].name);
        setFile(event.target.files[0].name);
      } else {
        event.target.value = null;
        Swal.fire({
          text: "Please select the gif/jpg/jpeg/png format image",
          confirmButtonText: "Close",
        });
      }
    }
  };

  useEffect(() => {
    async function data() {
      setpriceTypeOption(0);

      await setpriceType(ticket_list[0]);
      await setpriceType1(ticket_list_1[0]);
      await get_whitelst_data();
      await individual_details(publicKey.toString());
    }
    data();
  }, [publicKey]);

  const get_whitelst_data = async () => {
    if (!publicKey) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getWhitelist/" + publicKey.toString(),
        { mode: "cors" }
      );

      const data = await response.json();
      if (response.status === 404) {
        setIsWhiteListed(false);
      }
      if (response.status === 200) {
        setIsWhiteListed(true);
      }
      // if (data["addressType"] !== "") {
      //   if (data["addressType"] === "dao") {
      //     setDaoName(data["daoName"]);
      //     setDaoUrl(data["daoUrl"]);
      //     await onRaffleChange(data["addressType"]);
      //   }
      //   setcurrentUserType(data["addressType"]);
      // }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const open_dao_raffle = () => {
    navigate("/create-raffle");
  };

  let show_threshold = async (ticket_size) => {
    let percent_of_ticket_supply = (40 / 100) * ticket_size;
    let threshold_tickets = 900;
    if (percent_of_ticket_supply < threshold_tickets) {
      threshold_tickets = percent_of_ticket_supply;
    }

    setticketThreshold(threshold_tickets);
  };

  const authTwitter = async () => {
    navigate("/my-account");
  };

  const rentCalculation = async (ticket_s) => {
    let size = 8 + 32 + 32 + 32 * ticket_s + 8 + 32 * 1 + 8 + 32 * 1 + 24;
    let size2 =
      8 +
      32 +
      32 +
      1 +
      1 +
      8 +
      8 +
      8 +
      8 +
      8 +
      8 +
      8 +
      32 +
      32 +
      32 +
      8 +
      8 +
      1 +
      1 +
      32 +
      8 +
      8;
    let rent = await connection.getMinimumBalanceForRentExemption(size + size2);
    let r = rent / Math.pow(10, 9);
    setRent(r);
  };

  return (
    <>
      <>
        {isWhiteListed && publicKey && (
          <>
            <div className="dao-detected">
              {/* <div className="pb-10 mt-5 text-center">
                <h2 className="f-size-40  text-white ">Create New Raffle</h2>
              </div> */}
              <img className="reach-us-img" alt="" src={reach_us_img} />
              <div className="reach-us-to-whitelist">
                Your Wallet is Whitelisted as DAO's,{" "}
                <span className="reach-us-link" onClick={open_dao_raffle}>
                  Click here
                </span>{" "}
                to create raffle as DAO
                {/* <a
              href="https://twitter.com/TheVaultDAO"
              rel="noreferrer"
              target="_blank"
              className="reach-us-link"
            >
              {" "}
              Reach us
            </a> */}
              </div>
            </div>
          </>
        )}
      </>
      {!isWhiteListed && (
        <>
          {publicKey ? (
            <>
              <Modal
                show={NFTModal}
                onHide={handleCloseNFTModal}
                backdrop="static"
                className="nft-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Choose NFT for Raffle Prize
                    <div className="sub-note">
                      If you do not find the NFT below to choose the{" "}
                      <a
                        className="msg-note"
                        href="https://twitter.com/TheVaultDAO"
                        rel="noreferrer"
                        target="_blank"
                      >
                        contact us
                      </a>{" "}
                      to whitelist the same{" "}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isLoadingNFT && <PreLoader />}
                  {!isLoadingNFT && (
                    <form>
                      <div className="row g-3 g-lg-6">
                        {whitelistedToken.map((token, index) => (
                          <div className="col-md-3">
                            <a>
                              <input
                                type="radio"
                                name="emotion"
                                id={token["address"]}
                                className="input-hidden"
                                onChange={() => {
                                  setraffleName(token?.name);
                                  setselectedToken(token["address"]);
                                  setselectedNFTCmid(token["cmid"]);
                                  setselectedNFTImage(token["image"]);
                                }}
                              />
                              <label htmlFor={token.address}>
                                <img
                                  alt=""
                                  height="150"
                                  src={token?.image}
                                  width="150"
                                />
                              </label>
                              <div
                                className={`${
                                  isMobile ? "m-5" : "text-center m-5"
                                }`}
                              >
                                <span className="fs-4 text-white fw-bold">
                                  {token?.name}
                                </span>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    </form>
                  )}
                </Modal.Body>
                {!isLoadingNFT && (
                  <Modal.Footer>
                    <div className="text-center mt-9">
                      <button
                        type="button"
                        className="btn modal-btn-cancel me-3"
                        onClick={handleCloseNFTModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmitTokenModal}
                        type="button"
                        className="btn modal-btn-submit"
                        data-kt-modal-action-type="submit"
                      >
                        <span className="indicator-label">Select</span>
                      </button>
                    </div>
                  </Modal.Footer>
                )}
              </Modal>
              <Modal
                show={TokenModal}
                onHide={handleCloseTokenModal}
                backdrop="static"
                className="token-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Choose Token for Raffle Prize
                    <div className="msg-note">
                      Contact us to get your Token verified{" "}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form>
                    <div className="row g-3 g-lg-6">
                      {whitelistedToken.map((token, index) => (
                        <div className="col-md-4">
                          <a>
                            <input
                              type="radio"
                              name="emotion"
                              id={token["address"]}
                              className="input-hidden"
                              onChange={() =>
                                setselectedToken(token["address"])
                              }
                            />
                            <label htmlFor={token.address}>
                              <img
                                alt=""
                                height="150"
                                src={token?.image}
                                width="150"
                              />
                            </label>
                            <div
                              className={`${
                                isMobile ? "m-5" : "text-center m-5"
                              }`}
                            >
                              <span className="fs-6 text-gray-800 text-hover-primary fw-bold wl-token-name">
                                {token?.name}
                              </span>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className="text-center mt-9">
                      <button
                        type="button"
                        className="btn modal-btn-cancel me-3"
                        onClick={handleCloseTokenModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmitTokenModal}
                        type="button"
                        className="btn modal-btn-submit"
                        data-kt-modal-action-type="submit"
                      >
                        <span className="indicator-label">Select</span>
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>

              <Modal
                show={creatorGuideModal}
                onHide={handleCloseCreatorGuideModal}
                backdrop="static"
                className="token-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Creator’s Guide for Raffle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="msg-note">Terms & Conditions</div>
                  <div className="guide-container">
                    <ol>
                      <li>
                        When you create a raffle, the NFT prize you have chosen
                        will be transferred from your wallet into an escrow
                        wallet.
                      </li>
                      <li>
                        You can choose to sell up to 5000 tickets per raffle.
                      </li>
                      <li>
                        You can choose SOL or SOL + $SPL Token as ticket price.
                      </li>
                      <li>
                        You will be charged an up-front rent fee in SOL, which
                        will be taken in proportion to the number of tickets you
                        choose to raffle. The rent fee is refundable after the
                        raffle is closed.
                      </li>
                      <li>
                        You can specify the amount of time a raffle runs at the
                        creation of the raffle. Raffles minimum run time is 24
                        hours.
                      </li>
                      <li>
                        {" "}
                        Raffle platform fee is 3% from the ticket sales, Vault-x
                        holders will get a 100% fee waiver for staking or
                        holding a Vault-x NFT.
                      </li>
                      <li> Raffle CANNOT be edited once created.</li>
                      <li>
                        {" "}
                        When the raffle draw ends, you will have an option to
                        reveal the winner otherwise it will be revealed
                        automatically within 24 hours.{" "}
                      </li>
                      <li>
                        Raffle Cancellation:
                        <ol>
                          <li>
                            You can cancel the raffle if no tickets are sold,
                            your NFT and rent fee will be returned back to you.
                          </li>
                          <li>
                            If the ticket sales is less than the minimum
                            threshold value (40% of ticket supply with max cap
                            of 900 tickets ) then you will have option to cancel
                            raffle by paying 0.2 SOL penalty fees and your NFT
                            will be returned back to your wallet. Participants
                            can claim their refunds as per tickets bought. When
                            all the participants claim their refunds then you
                            can close the raffle and take back your rent fees.
                          </li>
                        </ol>
                      </li>
                      <li>
                        {" "}
                        Raffle, its agents, directors, or officers shall not
                        assume any liability or responsibility for your use of
                        Raffle, promoting or marketing the raffles.
                      </li>
                      <li>
                        {" "}
                        If you are not able to choose your NFT for Raffle due to
                        NFT Collection is not verified or any other reason,
                        please contact {""}{" "}
                        <img width="22px" alt="discord_img" src={discord_img} />
                        <span className="discord-id">
                          {" "}
                          Jack_Reacher_VaultX#7528
                        </span>{" "}
                        for immediate assistance.{" "}
                      </li>
                      <li>
                        {" "}
                        Please link your twitter for better visibility. Once
                        Raffle is created then it will be automatically posted
                        at{" "}
                        <a
                          href="https://twitter.com/Raffle_X_live"
                          rel="noreferrer"
                          className="btn twiiter-connect-btn me-2"
                          target="_blank"
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="inline-block w-6 ml-2 text-white-40 fill-current"
                          >
                            <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                          </svg>
                          Raffle_X_live
                        </a>
                        Follow this twitter for more updates.{" "}
                      </li>
                    </ol>
                  </div>
                </Modal.Body>
              </Modal>
              {isLoading && <PreLoader />}
              {!isLoading && (
                <div
                  id="kt_content_container"
                  className="d-flex flex-column-fluid align-items-start  create-raffle-page-container"
                >
                  <div
                    className={`${
                      isMobile
                        ? "px-5"
                        : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                    }`}
                  >
                    <div
                      className={`${
                        isMobile
                          ? ""
                          : "d-flex flex-column flex-lg-row-fluid py-10"
                      }`}
                    >
                      <div
                        className={`${
                          isMobile
                            ? ""
                            : "d-flex flex-center flex-column flex-column-fluid"
                        }`}
                      >
                        <div className="">
                          <form
                            onSubmit={handleSubmit}
                            className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                            id="kt_create_account_form"
                          >
                            <div
                              className="creator-guide mt-3"
                              onClick={() => {
                                setcreatorGuideModal(true);
                              }}
                            >
                              <i className="bi bi-info-square"></i> Creator
                              guide
                            </div>
                            <div className="row">
                              <div className="col-lg-9 col-md-9  col-sm-12">
                                <div className="pb-10 pb-lg-15">
                                  <h2 className="f-size-40 d-flex align-items-center text-white">
                                    Create New Raffle
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className={`${
                                  isMobile
                                    ? "col-xl-3 col-md-3 pb-5 pt-5"
                                    : "col-xl-3 col-md-3"
                                }`}
                              >
                                <div
                                  className="file-wrapper"
                                  style={{
                                    backgroundImage: `url(${selectedNFTImage})`,
                                  }}
                                  onClick={() => {
                                    setNFTModal(true);
                                    onChooseToken("nft");
                                  }}
                                ></div>
                                {ticketThreshold > 0 && (
                                  <div className="ticket-threshold mt-3">
                                    Minimum Threshold Value : {ticketThreshold}
                                  </div>
                                )}
                              </div>
                              <div className="col-xl-9 col-md-9">
                                <div className="pending step-2">
                                  <div>
                                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                                      <div className="col-lg-6">
                                        <label className="form-label mb-3  create-raffle-label">
                                          Raffle Created By
                                        </label>
                                        {individualTwitterName !== "" ? (
                                          <div className="f-size-20 twitter-user-name">
                                            <a
                                              href={
                                                "https://twitter.com/" +
                                                individualTwitterName
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {individualTwitterName}
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                className="inline-block w-6 ml-2 text-blue-400 fill-current"
                                              >
                                                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                              </svg>
                                            </a>
                                          </div>
                                        ) : (
                                          <div>
                                            <button
                                              onClick={authTwitter}
                                              className="btn twiiter-connect-btn me-2"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                className="inline-block w-6 ml-2 text-white-40 fill-current"
                                              >
                                                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                              </svg>
                                              Link Twiiter
                                            </button>
                                          </div>
                                          // <div className="f-size-20 text-white">
                                          //   {" "}
                                          //   Please click{" "}
                                          //   <Link
                                          //     to={"/my-account"}
                                          //     className="link"
                                          //   >
                                          //     here
                                          //   </Link>{" "}
                                          //   link your Twitter
                                          // </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                                      <div
                                        className={`${
                                          isMobile
                                            ? "col-xl-2 pb-5"
                                            : "col-xl-2"
                                        }`}
                                      >
                                        <label className="form-label mb-3  create-raffle-label">
                                          Ticket Size
                                        </label>

                                        <input
                                          type="number"
                                          min="1"
                                          className="form-control form-control-lg form-control-solid"
                                          value={ticketSupply}
                                          onChange={(e) => {
                                            setticketSupply(e.target.value);
                                            show_threshold(e.target.value);
                                            rentCalculation(e.target.value);
                                          }}
                                          placeholder=""
                                        />
                                        {ticketSupply !== "" && (
                                          <span className="ticket-threshold">
                                            Rent : {rent.toFixed(2)} SOL
                                          </span>
                                        )}
                                      </div>
                                      <div
                                        className={`${
                                          isMobile
                                            ? "col-xl-4 pb-5"
                                            : "col-xl-4"
                                        }`}
                                      >
                                        <label className="form-label mb-3  create-raffle-label">
                                          Ticket Type
                                        </label>

                                        <select
                                          className="raffle-price-type-select form-select form-select-solid"
                                          name="created_for"
                                          value={ticketType}
                                          onChange={(e) =>
                                            onTicketTypeChange(e.target.value)
                                          }
                                        >
                                          <option value="1">
                                            Single Ticket Price
                                          </option>
                                          <option value="2">
                                            Dual Ticket Price
                                          </option>
                                        </select>
                                      </div>
                                      <div
                                        className={`${
                                          isMobile
                                            ? "col-xl-3 pb-5"
                                            : "col-xl-3"
                                        }`}
                                      >
                                        <label className="form-label mb-3  create-raffle-label">
                                          Ticket Price
                                        </label>
                                        <div className="d-flex align-items-center">
                                          <input
                                            type="number"
                                            min="1"
                                            className="form-control raffle-price-text form-control-lg form-control-solid w-100px"
                                            value={ticketPrice}
                                            onChange={(e) =>
                                              setticketPrice(e.target.value)
                                            }
                                            placeholder=""
                                          />
                                          <select
                                            className="raffle-price-select form-select form-select-solid"
                                            name="created_for"
                                            value={priceTypeOption}
                                            onChange={(e) =>
                                              onPriceTypeChange(e.target.value)
                                            }
                                          >
                                            {ticket_list.map((t, k) => (
                                              <option value={k}>
                                                {t["Token_name"]}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      {ticketType === "2" && (
                                        <div className="col-xl-3">
                                          <label className="form-label mb-3  create-raffle-label">
                                            Ticket Price 2
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="number"
                                              min="1"
                                              className="form-control raffle-price-text form-control-lg form-control-solid w-100px"
                                              value={ticketPrice1}
                                              onChange={(e) =>
                                                setticketPrice1(e.target.value)
                                              }
                                              placeholder=""
                                            />
                                            <select
                                              className="raffle-price-select form-select form-select-solid"
                                              name="created_for"
                                              value={priceTypeOption1}
                                              onChange={(e) =>
                                                onPriceTypeChange1(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              {ticket_list_1.map((t, k) => (
                                                <option value={k}>
                                                  {t["Token_name"]}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                                      <div
                                        className={`${
                                          isMobile
                                            ? "col-xl-6 pb-5"
                                            : "col-xl-6"
                                        }`}
                                      >
                                        <label className="form-label mb-3 create-raffle-label">
                                          Max Ticket Per Wallet
                                        </label>

                                        <input
                                          type="number"
                                          min="1"
                                          className="form-control form-control-lg form-control-solid"
                                          value={maxTicketPerWallet}
                                          onChange={(e) =>
                                            setmaxTicketPerWallet(
                                              e.target.value
                                            )
                                          }
                                          placeholder="Unlimited"
                                        />
                                      </div>

                                      <div className="col-xl-6">
                                        <label className="form-label mb-3 create-raffle-label">
                                          Raffle End Date
                                        </label>

                                        <DatePicker
                                          minDate={moment()
                                            .add(0, "days")
                                            .toDate()}
                                          timeInputLabel="Time:"
                                          showTimeInput
                                          minTime={disabledHours}
                                          maxTime={setHours(
                                            setMinutes(new Date(), 0),
                                            23
                                          )}
                                          className="form-control form-control-solid"
                                          dateFormat="MM/dd/yyyy h:mm aa"
                                          selected={endTime}
                                          onChange={(date) => setendTime(date)}
                                        />

                                        <div className="mt-2 ticket-threshold">
                                          Min : 24 Hours
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                                      {raffleFor === "token" && (
                                        <div className="col-xl-6">
                                          <label className="form-label mb-3  create-raffle-label">
                                            Price Amount for winner
                                          </label>

                                          <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid"
                                            value={priceAmountPerWinner}
                                            onChange={(e) =>
                                              setpriceAmountPerWinner(
                                                e.target.value
                                              )
                                            }
                                            placeholder=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-stack pt-15 float-right">
                              <div className="mr-2">
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn bt-lg create-raffle-btn"
                                    onClick={handleSubmit}
                                  >
                                    Create Raffle
                                    <span className="svg-icon svg-icon-4 ms-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="18"
                                          y="13"
                                          width="13"
                                          height="2"
                                          rx="1"
                                          transform="rotate(-180 18 13)"
                                          fill="currentColor"
                                        ></rect>
                                        <path
                                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
      {!publicKey && (
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start  create-raffle-page-container"
        >
          <div
            className={`${
              isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
            }`}
          >
            <div
              className={`${
                isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
              }`}
            >
              <div
                className={`${
                  isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                }`}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="pb-10 text-center">
                      <h2 className="f-size-40  text-white ">
                        Create New Raffle
                      </h2>
                      {/* <h4 className="text-center text-white"> Please connect your wallet to continue.</h4> */}
                    </div>
                    <div className="create-raffle-wallet-connet">
                      <img className="reach-us-img" alt="" src={reach_us_img} />
                      <div className="reach-us-to-whitelist">
                        Please connect your wallet to create a raffle.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
