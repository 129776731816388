import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import "./header.css";
import logo_white from "../images/raffle-logo.png";
import logo_black from "../images/logo-black.png";

import user_avator from "../images/avatar.png";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { Button } from "react-bootstrap";

export const Header = ({}) => {
  const { connection } = useConnection();
  const location = useLocation();

  const { wallet, publicKey, sendTransaction } = useWallet();
  const [tps, setTps] = useState<any | null>(null);
  const myFakeCallback = () => console.log(publicKey.toString());
  const admin_wallet = process.env.REACT_APP_ADMIN_ACCOUNT;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [menuChecked, setMenuChecked] = useState(false);
  useEffect(() => {
    const intervalCall = setInterval(() => {
      refreshTps();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const refreshTps = async () => {
    // await get_tps();
  };

  const get_tps = async () => {
    try {
      const response = await fetch("https://api.solanart.io/get_solana_tps", {
        headers: new Headers({
          "User-Agent": "Mozilla/4.0",
        }),
        mode: "cors",
      });
      const data = await response.json();
      if (data) {
        setTps(data["tps"]);
      } else {
        setTps("");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <>
      {isMobile ? (
        <nav role="navigation">
          <div id="menuToggle">
            <input type="checkbox" onClick={() => setMenuChecked(!menuChecked)} checked={menuChecked}  />

            <span></span>
            <span></span>
            <span></span>

            <ul id="menu">
              <li className="logo-mobile-li">
                <Link to={{ pathname: "/" }} state={{ from: "" }} onClick={() => setMenuChecked(false)}>
                  <img
                    alt="Logo"
                    src={logo_white}
                    className="h-50px h-lg-50px"
                  />
                </Link>
                <WalletMultiButton className="btn  mt-5 connect-wallet-btn fw-bolder w-100 w-lg-auto" />
              </li>
              {/* {publicKey && (
                <li>
                  <Link
                    className=""
                    to={{ pathname: "/create-raffle" }}
                    state={{ from: "create-raffle" }}
                    onClick={() => setMenuChecked(false)}
                  >
                    <i className="bi bi-plus-circle-dotted"></i>
                    Create Raffle By DAO
                  </Link>
                </li>
              )} */}
              <li>
                <Link
                  className=""
                  to={{ pathname: "/create-individual" }}
                  state={{ from: "create-individual" }}
                  onClick={() => setMenuChecked(false)}
                >
                  <i className="bi bi-plus-circle-dotted"></i>
                  Create Raffle
                </Link>
              </li>
              {publicKey && publicKey.toString() === admin_wallet && (
                <li>
                  <Link
                    className=""
                    to={{ pathname: "/white-list" }}
                    state={{ from: "white-list" }}
                    onClick={() => setMenuChecked(false)}
                  >
                    <i className="bi bi-sticky"></i>
                    Add To Whitelist
                  </Link>
                </li>
              )}
              {publicKey && (
                <li>
                  <Link
                    to={{ pathname: "/my-account" }}
                    state={{ from: "my-account" }}
                    onClick={() => setMenuChecked(false)}
                  >
                    <i className="bi bi-person"></i>
                    My Account
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </nav>
      ) : (
        ""
      )}
      <div
        id="kt_header"
        className="header"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      >
        <div className="container-xxl d-flex flex-grow-1 flex-stack">
          <div
            className={`${
              isMobile ? "mx-auto" : "d-flex align-items-center me-5"
            }`}
          >
            {/* <div
              className="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3"
              id="kt_header_menu_toggle"
            >
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div> */}

            <Link to={{ pathname: "/" }} state={{ from: "" }}>
              <img alt="Logo" src={logo_white} className="h-50px h-lg-50px" />
            </Link>
            {publicKey &&
              publicKey.toString() === admin_wallet &&
              !isMobile && (
                <div className="d-flex align-items-center ms-3 ms-lg-4">
                  <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                    <Link
                      className="btn create-dao-btn fw-bolder w-100 w-lg-auto"
                      to={{ pathname: "/white-list" }}
                      state={{ from: "white-list" }}
                    >
                      <i className="bi bi-sticky"></i>
                      Add To Whitelist
                    </Link>
                  </div>
                </div>
              )}
          </div>
          {tps !== "" && tps !== null && tps !== undefined && (
            <div className="tps">Solana TPS: {tps}</div>
          )}
          <div className="d-flex align-items-center flex-shrink-0">
            <>
              {isMobile ? (
                <>
                  {/* <nav role="navigation">
                    <div id="menuToggle">
                      <input type="checkbox" />

                      <span></span>
                      <span></span>
                      <span></span>

                      <ul id="menu">
                        {publicKey && (
                          <>
                            <li>
                              <Link
                                className=""
                                to={{ pathname: "/create-raffle" }}
                                state={{ from: "create-raffle" }}
                              >
                                Create Raffle By DAO
                              </Link>
                            </li>
                            <li>
                              <Link
                                className=""
                                to={{ pathname: "/create-individual" }}
                                state={{ from: "create-individual" }}
                              >
                                Create Raffle By Individual
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{ pathname: "/my-account" }}
                                state={{ from: "my-account" }}
                              >
                                My Account
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                        </li>
                      </ul>
                    </div>
                  </nav> */}
                </>
              ) : (
                <>
                  {/* {publicKey && (
                    <>
                      <div className="d-flex align-items-center ms-3 ms-lg-4">
                        <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                          <Link
                            className="btn create-dao-btn fw-bolder w-100 w-lg-auto"
                            to={{ pathname: "/create-raffle" }}
                            state={{ from: "create-raffle" }}
                          >
                            <i className="bi bi-plus-circle-dotted"></i>
                            Create Raffle By DAO
                          </Link>
                        </div>
                      </div>
                    </>
                  )} */}
                  <div className="d-flex align-items-center ms-3 ms-lg-4">
                    <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                      <Link
                        className="btn create-individual-btn fw-bolder w-100 w-lg-auto"
                        to={{ pathname: "/create-individual" }}
                        state={{ from: "create-individual" }}
                      >
                        <i className="bi bi-plus-circle-dotted"></i>
                        Create Raffle
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex align-items-center ms-3 ms-lg-4">
                    <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                      <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                    </div>
                  </div>

                  {publicKey && (
                    <Link
                      to={{ pathname: "/my-account" }}
                      state={{ from: "my-account" }}
                    >
                      <div className="d-flex align-items-center ms-3 ms-lg-4">
                        <div className="flex-shrink-0 p-4 p-lg-0 me-lg-2">
                          <img className="user_avator" src={user_avator} />
                        </div>
                      </div>
                    </Link>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </div>
      {/* <div className="float-container">
        <a href="http://v1.raffle.thevault-x.com/" target="_blank" >Previous Raffle Winners</a>
      </div> */}
    </>
  );
};

export default Header;
